import { HTTP } from '../config/axios'
import common from '../common';
import AppConstants from '../constants'
import { SNACKBAR } from '../enums/snackbar';
import { STORE } from '../enums/store'
import { HTTP_METHODS } from '../enums/http';
export default class Auth {
    constructor(utils) {
        this.utils = utils;
    }

    async login(email, password) {
        try {
            await HTTP.get('/sanctum/csrf-cookie');
            let loginresp = await HTTP.post("/api/spalogin", {
                "email": email,
                "password": password
            });
            this.utils.$store.commit(`${STORE.AUTH}/UPDATE_USER`, loginresp.data);
            await this.utils.$store.dispatch(`${STORE.AUTH}/GET_CURRENT_USER_PERMISSIONS`, { utils: this.utils, id: loginresp.data?.id, role_id: loginresp.data?.role_id });
            common.showNotification({
                message: AppConstants.LOGIN_SUCCESS_MESSAGE,
                type: SNACKBAR.SUCCESS
            }, this.utils);
            this.utils.$store.dispatch(`${STORE.APP}/LOAD_ALL_LOOKUPS`, this.utils);
            this.utils.$router.push('/dashboard');

        } catch (err) {
            common.showNotification({
                message: AppConstants.LOGIN_FAILED,
                statuscode: err.response?.status || 0,
                type: SNACKBAR.ERROR
            }, this.utils);
        }
        return;
    }

    async isauthorized() {
        let status = false;
        try {
            const resp = await HTTP.get('/api/isauthorized');
            status = resp.data;
        } catch (err) {
            status = false;
        } 
        return status;
    }

    async logout() {
        let status = false;
        try{
            await HTTP.post("/api/spalogout");
            this.utils.$store.commit(`${STORE.AUTH}/UPDATE_USER`, null);
            this.utils.$store.commit(`${STORE.AUTH}/SET_CURRENT_USER_PERMISSIONS`, []);
            common.showNotification({
                message: AppConstants.LOGOUT_SUCCESS_MESSAGE,
                type: SNACKBAR.SUCCESS
            }, this.utils);

            status = true;
        } catch (err) {
            common.showNotification({
                message: err.message,
                statuscode: err.response?.status || 0,
                type: SNACKBAR.ERROR
            }, this.utils);
            status = false;
        }
        return status;
    }

    async getUsers(per_page) {
        let response = await common.loadAllResults({ 
                url: `/api/users/${per_page}`,
                utils: this.utils
            });
        return response?.map(x => ({
            id: x.id,
            name: x.name,
            email: x.email,
            role_id: x.role_id,
            locations: x.location || []
        })) || [];
    }

    async registerUser(user) {
        let payload = {
            name: user.name,
            email: user.email,
            role_id: user.role_id,
            location: user.locations.map(x => x.id)
        };
        return await common.saveRequest({
            method: HTTP_METHODS.POST,
            url: `/api/auth/register`,
            payload,
            utils: this.utils
        });
    }

    async updateUser(user) {
        let payload = {
            name: user.name,
            role_id: user.role_id, 
            location: user.locations.map(x => x.id)
        };
        return await common.saveRequest({
            method: HTTP_METHODS.PUT,
            url: `/api/auth/updateUser/${user.id}`,
            payload,
            utils: this.utils
        });
    }

    async forgotPassword(email) {
        await HTTP.get('/sanctum/csrf-cookie');
        let payload = {
            email
        };
        return await common.saveRequest({
            method: HTTP_METHODS.POST,
            url: `/api/forgot-password`,
            payload,
            utils: this.utils
        });
    }

    async resetPassword(inputs) {
        await HTTP.get('/sanctum/csrf-cookie');
        let payload = {
            token: inputs.token,
            email: inputs.email,
            password: inputs.password,
            password_confirmation: inputs.password_confirmation
        };
        return await common.saveRequest({
            method: HTTP_METHODS.POST,
            url: `/api/reset-password`,
            payload,
            utils: this.utils
        });
    }
}